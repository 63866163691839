<template>
  <div class="container pb-14 lg:pb-28">
    <AppSlider :actions="data.buttons" :slider-data="data.linkList" :title="data.title">
      <template #default="props">
        <NuxtLink
          :to="
            props.slide.link
              ? localePathByType('page', props.slide.link.slug)
              : props.slide.externalLink
          "
          :target="props.slide.externalLink ? '_blank' : undefined"
        >
          <Image
            v-if="props.slide.image.responsiveImage"
            class="aspect-h-4 aspect-w-3 mb-5 lg:mb-6"
            img-class="object-cover hover:scale-110 duration-300"
            :src="props.slide.image.responsiveImage.srcSet"
            :src-webp="props.slide.image.responsiveImage.webpSrcSet"
            :width="props.slide.image.responsiveImage.width"
            :height="props.slide.image.responsiveImage.height"
            :alt="props.slide.image.responsiveImage.alt"
          />
          <p class="text-base font-medium text-neutral-900 lg:text-2xl">{{ props.slide.title }}</p>
        </NuxtLink>
      </template>
    </AppSlider>
  </div>
</template>

<script setup lang="ts">
defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const { localePathByType } = useLocalePathByType()
</script>
